.btn-color{
    background-color: #0e1c36;
    color: #e2dddd;
    
  }
  
  .profile-image-pic{
    height: 200px;
    width: 200px;
    object-fit: cover;
  }
  
  
  
  .cardbody-color{
    background-color: #ebf2fa;
  }
  
  a{
    text-decoration: none;
  }